import { graphql } from "gatsby"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { IntlProvider } from "react-intl"

import ogImage from "@/assets/images/seo/ogimageFr.jpg"
import Footer from "@/components/Footer/Footer"
import IntroSection from "@/components/IntroSection/IntroSection"
import Layout from "@/components/Layout/Layout"
import Navbar from "@/components/Navbar/Navbar"
import RetroSection from "@/components/RetroSection/RetroSection"
import Seo from "@/components/Seo/Seo"
import SpeakerSection from "@/components/SpeakerSection/SpeakerSection"
import StickyMobileCta from "@/components/StickyMobileCta"
import de from "@/intl/de"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollToPlugin)
}

const IndexPage = ({ data }) => {
  const speakerImages = data.allFile.edges
  const spearkersSectionRef = useRef(null)
  const formRef = useRef(null)
  const retroSectionRef = useRef(null)
  const [showNav, seShowNav] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      let currentPosition = window.pageYOffset
      let windowSize = window.innerHeight
      if (currentPosition > windowSize) {
        seShowNav(true)
      } else {
        seShowNav(false)
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [showNav])

  const scrolltoConf = () => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: {
        y: spearkersSectionRef.current,
      },
      overwrite: true,
      ease: "linear",
    })
  }

  const scrolltoRetro = () => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: {
        y: retroSectionRef.current,
      },
      overwrite: true,
      ease: "linear",
    })
  }

  const scrolltoForm = () => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: {
        y: formRef.current,
      },
      overwrite: true,
      ease: "linear",
    })
  }

  return (
    <IntlProvider locale="de" messages={de}>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="0njh37hxzi11zz2kg5f3wem68cb7xu"
        />
      </Helmet>
      <Seo
        title="Content Summit 2023 – Alles, was du über Content wissen musst"
        desc="Die Content Summit von PlayPlay ist das Event für Marketing- und Kommunikationsprofis! Hier trifft Inspiration auf Tipps aus der Praxis."
        ogImage={ogImage}
      />
      <Layout>
        {showNav && (
          <Navbar
            scrolltoConf={scrolltoConf}
            scrolltoForm={scrolltoForm}
            scrolltoRetro={scrolltoRetro}
          />
        )}
        <IntroSection
          scrolltoConf={scrolltoConf}
          scrolltoRetro={scrolltoRetro}
          formRef={formRef}
          withForm={true}
        />
        <SpeakerSection
          scrolltoConf={scrolltoConf}
          speakerImages={speakerImages}
          spearkersSectionRef={spearkersSectionRef}
        />
        <Footer />
        {showNav && <StickyMobileCta scrolltoForm={scrolltoForm} />}
      </Layout>
    </IntlProvider>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "images/speakers" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { fit: COVER }
              width: 720
            )
          }
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.object,
}
